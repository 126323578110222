import axios from "axios";
import {
  API_BASEURL,
  API_CCBILL,
  headers,
} from "../components/config/constants";

const isDev = process.env.REACT_APP_ENV === "development";

const RequestService = {
  get: function (path, heads = headers) {
    return axios
      .get(API_BASEURL + path, { headers: heads })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },
  getCcbill: function (path, heads = headers) {
    // console.log(isDev ? "http://localhost:8000/" : "" + API_CCBILL);
    return axios
      .get((isDev ? "http://localhost:8000/" : "") + API_CCBILL + path, {
        headers: heads,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },
  postCcbill: function (path, params, heads = headers) {
    if (isDev) {
      return fetch((isDev ? "http://localhost:8000/" : "") + API_CCBILL + path)
        .then((response) => response)
        .catch((error) => {
          throw error;
        });
    } else {
      return axios
        .post(
          (isDev ? "http://localhost:8000/" : "") + API_CCBILL + path,
          params,
          {
            headers: heads,
          }
        )
        .then((response) => response)
        .catch((error) => {
          throw error;
        });
    }
  },
  post: function (path, params, heads = headers) {
    return axios
      .post(API_BASEURL + path, params, { headers: heads })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },
  put: function (path, params, heads = headers) {
    return axios
      .put(API_BASEURL + path, params, { headers: heads })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },
  delete: function (path, heads = headers) {
    return axios
      .delete(API_BASEURL + path, { headers: heads })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },
  putFile: function (url, params, heads = headers) {
    return axios
      .put(url, params, { headers: heads })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },
};

export default RequestService;
