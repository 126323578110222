import { image_headers } from "../components/config/constants";
import RequestService from "./RequestService";

const UserService = {
  otpVerifyByEmail: function (params) {
    return RequestService.post(`/user/otp/verify-by-email`, params);
  },
  setWatermarks: function (params) {
    return RequestService.post(`/user/set-watermarks`, params);
  },
  otpVerifyById: function (params) {
    return RequestService.post(`/user/otp/verify-by-id`, params);
  },
  userDetails: function (params) {
    return RequestService.post(`/user/details`, params);
  },
  getPendingRequests: function () {
    return RequestService.get(`/kyc/pending-request`);
  },
  getTotalReactions: function () {
    return RequestService.get(`/user/total-post-reactions`);
  },
  getTotalComments: function () {
    return RequestService.get(`/user/total-post-comments`);
  },
  isUserPasswordSet: function () {
    return RequestService.get(`/user/is-password-set`);
  },
  getSubscribersStats: function (params) {
    return RequestService.post(`/user/subscribersStats`, params);
  },
  sendLinkSetGooglePassword: function (params) {
    return RequestService.post(`/user/create-password`, params);
  },
  getReachChart: function (params) {
    return RequestService.post(`/user/reachStats`, params);
  },
  getPostImpressions: function (params) {
    return RequestService.post(`/user/impressions-posts`, params);
  },
  getClipImpressions: function (params) {
    return RequestService.post(`/user/impressions-clips`, params);
  },
  getProfileImpressions: function (params) {
    return RequestService.post(`/user/impressions-profile`, params);
  },
  getMassMessageImpressions: function (params) {
    return RequestService.post(`/user/impressions-mass-messages`, params);
  },
  getMassMessageReach: function () {
    return RequestService.get(`/user/mass-message-reach`);
  },
  getMassMessageAverageConversion: function () {
    return RequestService.get(`/user/average-conversion-rate`);
  },
  getEarningsPerMonth: function () {
    return RequestService.get(`/user/earnings-per-month`);
  },
  getEarningsDetail: function (params) {
    return RequestService.post(`/user/earnings-detail`, params);
  },
  getSummaryTotalEarnings: function () {
    return RequestService.get(`/user/summary-total-earnings`);
  },
  getSummaryTotalReferralsEarnings: function () {
    return RequestService.get(`/user/summary-total-referrals-earnings`);
  },
  createReferralCode: function () {
    return RequestService.post(`/user/generate-referral-code`, {});
  },
  getReferralsList: function (params) {
    return RequestService.post(`/user/list-referrals`, params);
  },
  addShare: function (params) {
    return RequestService.post(`/share/add`, params);
  },
  reviewCreator: function (params) {
    return RequestService.post(`/user/give-rating`, params);
  },
  storiesCreator: function (params) {
    return RequestService.post(`/user/get-stories`, params);
  },
  userUpdatePicture: function (params) {
    return RequestService.post(`/user/profile-picture`, params, image_headers);
  },
  userUpdateCover: function (params) {
    return RequestService.post(`/user/cover-image`, params, image_headers);
  },
  toggleComments: function (params) {
    return RequestService.post(`/user/toggle-comments`, params);
  },
  toggleAvailability: function (params) {
    return RequestService.post(`/user/toggle-availability`, params);
  },
  toggleSubscribersVisibility: function (params) {
    return RequestService.post(`/user/toggle-subscribers-visibility`, params);
  },
  getQRCode: function (params) {
    return RequestService.post(`/user/profile-qr-code`, params);
  },
  sessionsList: function (page, totalCount) {
    return RequestService.get(
      `/user/login-sessions?page=${page}&totalCount=${totalCount}`
    );
  },
  getPaymentHistory: function (params) {
    return RequestService.post(`/user/transactions`, params);
  },
  addTransferMethod: function (params) {
    return RequestService.post(`/withdraw-method`, params);
  },
  closeSessions: function (params) {
    return RequestService.post(`/user/close-login-sessions`, params);
  },
  updateProfile: function (params) {
    return RequestService.post(`/user/update-profile`, params);
  },
  addCheckCreator: function (params) {
    return RequestService.post(`/check/add`, params);
  },
  getAllFans: function (params) {
    return RequestService.post(`/manage-subscribers/get-all-fans`, params);
  },
  getSubscribersManage: function (params) {
    return RequestService.post(`/manage-subscribers/get-subscribers`, params);
  },
  getExpired: function (params) {
    return RequestService.post(`/manage-subscribers/get-expired`, params);
  },
  deleteUserAccount: function (params) {
    return RequestService.post(`/user/delete-account`, params);
  },
  checkUserExists: function (params) {
    return RequestService.post(`/user/check-exists`, params);
  },
  verifyUserAccount: function (params) {
    return RequestService.post(`/user/verify-account`, params);
  },
  checkPendingRequest: function () {
    return RequestService.get(`/check/pending-request`);
  },
  userSessionDetails: function (params) {
    return RequestService.post(`/user/session-details`, params);
  },
  deleteSingleSession: function (params) {
    return RequestService.post(`/user/delete-login-session`, params);
  },
  withdraw: function (params) {
    return RequestService.post(`/user/withdrawal`, params);
  },
  creatorList: function (params) {
    return RequestService.post(`/user/creator-list`, params);
  },
  appliedCreator: function (params) {
    return RequestService.post(`/user/applied-creator`, params);
  },
  applyKyc: function (params) {
    return RequestService.post(`/kyc/add`, params);
  },
  getScheduleWithdraw: function () {
    return RequestService.get(`/user/schedule-withdraw`);
  },
  postPurchase: function (params) {
    return RequestService.post(`/user-post/purchase`, params);
  },
  manualWithdraw: function () {
    return RequestService.post(`/user/manual-withdraw`, {});
  },
  scheduleWithdraw: function (params) {
    return RequestService.post(`/user/schedule-withdraw`, params);
  },
  getTotalSubscription: function (params) {
    return RequestService.post(`/subscription/get-total`, params);
  },
  getTotalClip: function (params) {
    return RequestService.post(`/user-clip/get-total`, params);
  },
  getTotalPost: function (params) {
    return RequestService.post(`/user-post/get-total`, params);
  },
  getCounterSubscribers: function () {
    return RequestService.get(`/manage-subscribers/counters`);
  },
  getYourSub: function (params) {
    return RequestService.post(`/user/get-subscribers`, params);
  },
  getNewSub: function (params) {
    return RequestService.post(`/manage-subscribers/get-new`, params);
  },
  getMyContentClip: function (params) {
    return RequestService.post(`/user-clip/get-purchased`, params);
  },
  getMyContentPost: function (params) {
    return RequestService.post(`/user-post/get-purchased`, params);
  },
  updateCategory: function (params) {
    return RequestService.post(`/user/add-remove-category`, params);
  },
  getSuggestion: function () {
    return RequestService.get(`/user/suggestions-for-you`);
  },
  getWallet: function () {
    return RequestService.get(`/user/wallet`);
  },
  getWithdrawMethod: function () {
    return RequestService.get(`/withdraw-method`);
  },
  myContentCounters: function (params) {
    return RequestService.get("/user-post/counters");
  },
  subscriptionPlanList: function (params) {
    return RequestService.get(`/subscription-plan/list`, params);
  },
  purchaseFreeSubscriptionPlan: function (params) {
    return RequestService.post(`/subscription/purchase`, params);
  },
  getSubscribedCreatorDetails: function (params) {
    return RequestService.post(`/subscribed/user-details`, params);
  },
  getSubscribedCreatorList: function () {
    return RequestService.get(`/subscribed/user-list`);
  },
  cancelSubscription: function (params) {
    return RequestService.post(`/subscription/cancel`, params);
  },
  addPromotion: function (params) {
    return RequestService.post(`/promotion`, params);
  },
  deletePromotion: function (id) {
    return RequestService.delete("/promotion/" + id);
  },
  getPromotions: function (params) {
    return RequestService.get(`/promotion`);
  },
  getCreatedPlanList: function (params) {
    return RequestService.post(`/subscription-plan/list/creator`, params);
  },
  getSubscriptionPlans: function (params) {
    return RequestService.post(`/subscription-product/list`, params);
  },
  createSubscriptionPlan: function (params) {
    return RequestService.post(`/subscription-plan/add`, params);
  },
  updateSubscriptionPlan: function (params) {
    return RequestService.post(`/subscription-plan/update`, params);
  },
  deleteSubscriptionPlan: function (params) {
    return RequestService.post(`/subscription-plan/delete`, params);
  },
  archiveSubscriptionPlan: function (params) {
    return RequestService.post(`/subscription-plan/archive`, params);
  },
  unarchiveSubscriptionPlan: function (params) {
    return RequestService.post(`/subscription-plan/unarchive`, params);
  },
  getAddedCardList: function () {
    return RequestService.get(`/stripe/card-list`);
  },
  addCardToStripe: function (params) {
    return RequestService.post(`/stripe/add-card`, params);
  },
  updateCardToStripe: function (params) {
    return RequestService.post(`/stripe/edit-card`, params);
  },
  deleteCardToStripe: function (params) {
    return RequestService.post(`/stripe/delete-card`, params);
  },
  deleteCardToCcbill: function (params) {
    return RequestService.post(`/ccbill/delete-card`, params);
  },
  canDeleteCardToStripe: function (params) {
    return RequestService.post(`/stripe/can-delete-card`, params);
  },
  canDeleteCardToCcbill: function (params) {
    return RequestService.post(`/ccbill/can-delete-card`, params);
  },
  purchaseSubscriptionPlan: function (params) {
    return RequestService.post(`/subscription/purchase`, params);
  },
  purchaseClip: function (params) {
    return RequestService.post(`/user-clip/purchase`, params);
  },
  addSessionSubscriptionDetails: function (params) {
    return RequestService.post(`/subscription/session-details`, params);
  },
  getTaxDetailsForSubscription: function (params) {
    return RequestService.post(`/subscription/count-tax`, params);
  },
  deleteInactiveSubscriptionDetails: function (params) {
    return RequestService.post(`/subscription/delete`, params);
  },
  getCreatorListByName: function (params) {
    return RequestService.post(`/user/search-creator`, params);
  },
  getSubscriptionPlanByUser: function (params) {
    return RequestService.post(`/subscription/subscription-list`, params);
  },
  giveRatingToUser: function (params) {
    return RequestService.post(`/user/give-rating`, params);
  },
  getRating: function (params) {
    return RequestService.post(`/user/get-rating-by-user`, params);
  },
  getEarnings: function (params) {
    return RequestService.post(`/user/earnings`, params);
  },
  getAverageRating: function (params) {
    return RequestService.post(`/user/get-average-rating`, params);
  },
  manageChatDetails: function (params) {
    return RequestService.post(`/user/settings/get-chat-media-info`, params);
  },
  editChatDetails: function (params) {
    return RequestService.post(`/user/settings/chat-media`, params);
  },
  getSubscribers: function () {
    return RequestService.get(`/subscribers/list`);
  },
  getExpiredSubscribers: function () {
    return RequestService.get(`/subscribers/expired`);
  },
  getNewSubscribers: function () {
    return RequestService.get(`/subscribers/new`);
  },
};

export default UserService;
