import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BiSolidCreditCardFront, BiSolidCalendarEvent } from "react-icons/bi";
import { MdInfo } from "react-icons/md";
import Loader from "../../Loader.jsx";
import { REDUX_LOCAL_STORE_KEY } from "../../config/constants.js";
import UserService from "../../../services/UserService.js";
import GeneralService from "../../../services/GeneralService.js";
import CcbillService from "../../../services/ccbillService.js";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: localStorage.getItem("theme").includes("theme-dark")
        ? "white"
        : "#000000",
      fontWeight: 300,
      fontFamily: "'solitaire-mvb-pro', Avenir, Arial, sans-serif",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: localStorage.getItem("theme").includes("theme-dark")
          ? "white"
          : "#000000",
      },
      "::placeholder": {
        color: "#888888",
      },
    },
    invalid: {
      iconColor: "#ff0000",
      color: "#ff0000",
    },
  },
};

const Input = ({ title, className, placeholder, type }) => {
  return (
    <div className={className}>
      <label className="text-color-dark d-block fw-700 font-xssss">
        {title}
      </label>
      <input
        className="modal-input rounded-pill px-4 py-2"
        placeholder={placeholder}
        type={type ? type : "text"}
      />
    </div>
  );
};

const AddNewCardModalCcbill = ({
  show,
  handleModal,
  handleLoading,
  onSubmit,
  propsT,
  loader,
}) => {
  const [user, setUser] = useState({
    name: "",
  });
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    cardNumberDisplay: "",
    cardExpiry: "",
    cardCvc: "",
  });

  const [shippingAddress, setShippingAddress] = useState({
    address: "",
    postCode: "",
    city: "",
    state: "",
  });
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);

  const handleSelectedCountry = (event) => {
    setCountry(event.target.value);
  };

  const submitCcbill = async (event) => {
    if (
      (country !== null &&
        country !== undefined &&
        country !== "Select country" &&
        cardDetails.cardNumber.length === 16,
      parseInt(cardDetails.cardExpiry.slice(0, 2)) > 0 &&
        parseInt(cardDetails.cardExpiry.slice(0, 2)) < 13 &&
        parseInt(cardDetails.cardExpiry.slice(3, 5))) >=
        parseInt(new Date().getFullYear().toString().slice(-2)) &&
      parseInt(cardDetails.cardCvc) > 99
    ) {
      event.preventDefault();
      let paramsListCard = {
        expYear: "20" + parseInt(cardDetails.cardExpiry.slice(3, 5)),
        expMonth: cardDetails.cardExpiry.slice(0, 2),
        first4: cardDetails.cardNumber.slice(0, 4),
        last4: cardDetails.cardNumber.slice(12, 16),
        billingDetails: {
          country: country,
          fullName: user.name + " " + user.surname,
        },
      };
      let params = {
        clientAccnum: 954437,
        clientSubacc: 0,
        customerInfo: {
          customerFname: user.name,
          customerLname: user.surname,
          address1: shippingAddress.address,
          address2: null,
          city: shippingAddress.city,
          state: shippingAddress.state,
          country: country,
          zipcode: shippingAddress.postCode,
          email: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
            .authentication?.userData?.email,
          ipAddress: ipv4,
          phoneNumber: null,
          browserHttpUserAgent: null,
          browserHttpAccept: null,
          browserHttpAcceptEncoding: null,
          browserHttpAcceptLanguage: null,
        },
        paymentInfo: {
          creditCardPaymentInfo: {
            cardNum: cardDetails.cardNumber,
            cvv2: cardDetails.cardCvc,
            nameOnCard: user.name + " " + user.surname,
            expYear: "20" + parseInt(cardDetails.cardExpiry.slice(3, 5)),
            expMonth: cardDetails.cardExpiry.slice(0, 2),
          },
        },
        subscriptionId: 0,
        timeToLive: null,
        validNumberOfUse: null,
      };
      onSubmit(params, paramsListCard);
    } else {
      toast.error(propsT("common.fillAll"));
    }
  };

  const handleCvvChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    setCardDetails({
      ...cardDetails,
      cardCvc: value,
    });
  };

  const handleExpDateChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    if (value.length >= 3) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
    }
    setCardDetails({
      ...cardDetails,
      cardExpiry: value,
    });
  };

  const handleCardChange = (event) => {
    const arrayNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

    if (arrayNumbers.includes(event?.key)) {
      if (cardDetails?.cardNumber?.length < 16) {
        const newNumber = cardDetails.cardNumber + event.key;
        const newDisplayNumber =
          newNumber.slice(0, 4) +
          " " +
          newNumber.slice(4, 8) +
          " " +
          newNumber.slice(8, 12) +
          " " +
          newNumber.slice(12, 16);

        setCardDetails({
          ...cardDetails,
          cardNumber: newNumber,
          cardNumberDisplay: newDisplayNumber,
        });
      } else {
        setCardDetails({
          ...cardDetails,
          cardNumberDisplay: cardDetails.cardNumberDisplay,
        });
      }
    } else {
      setCardDetails({
        ...cardDetails,
        cardNumberDisplay: cardDetails.cardNumberDisplay,
      });
    }

    if (
      (event?.keyCode === 8 || event?.keyCode === 46) &&
      cardDetails?.cardNumber?.length > 0
    ) {
      const newNumberCard = cardDetails.cardNumber.slice(
        0,
        cardDetails.cardNumber.length - 1
      );

      const newDisplayNumber =
        newNumberCard.slice(0, 4) +
        " " +
        newNumberCard.slice(4, 8) +
        " " +
        newNumberCard.slice(8, 12) +
        " " +
        newNumberCard.slice(12, 16);
      setTimeout(() => {}, 0);
      setCardDetails({
        ...cardDetails,
        cardNumber: newNumberCard,
        cardNumberDisplay: newDisplayNumber,
      });
    }
  };

  const handleTextChange = (event, type) => {
    if (type === "user") {
      let name = event.target.name;
      let value = event.target.value;
      setUser({ ...user, [name]: value });
    } else if (type === "address") {
      let name = event.target.name;
      let value = event.target.value;
      setShippingAddress({ ...shippingAddress, [name]: value });
    } else if (type === "card") {
      let name = event.elementType;
      let value = event.complete;
      setCardDetails({ ...cardDetails, [name]: value });
    }
  };

  const getCountryList = useCallback(() => {
    let params = {
      search: "",
    };

    GeneralService.getCountryList({ ...params })
      .then((res) => {
        if (res.status === 200) {
          setCountryList(res?.data?.data?.data);
        }
      })
      .catch(() => {
        handleLoading?.(false);
      });
  }, [handleLoading]);
  const [ipv4, setIp] = useState("");
  useEffect(() => {
    fetch("https://geolocation-db.com/json/")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        setIp(res.IPv4);
      });
    getCountryList();
  }, [getCountryList]);

  return (
    <Modal
      show={show}
      onHide={() => handleModal(false)}
      keyboard={false}
      centered
    >
      {loader && <Loader />}
      <Modal.Header closeButton className="ps-3 pt-2 pb-2">
        <span className="text-color-dark fw-700 font-xssss">
          {propsT("manageCards.addCard")}
        </span>
      </Modal.Header>
      <Modal.Body className="p-4 add-card-modal">
        <div className="row">
          <div className="form-group mb-2">
            <label className="text-color-dark d-block fw-700 label-font">
              {propsT("manageCards.form.name.label")}
            </label>
            <div className="form-group icon-tab bg-payment-white rounded-3 mb-1">
              <input
                type="text"
                className="modal-input rounded-pill px-4 py-2"
                placeholder={propsT("manageCards.form.name.placeholder")}
                name="name"
                value={user.name}
                onChange={(event) => handleTextChange(event, "user")}
              />
            </div>
          </div>
          <div className="form-group mb-2">
            <label className="text-color-dark d-block fw-700 label-font">
              {propsT("manageCards.form.surname.label")}
            </label>
            <div className="form-group icon-tab bg-payment-white rounded-3 mb-1">
              <input
                type="text"
                className="modal-input rounded-pill px-4 py-2"
                placeholder={propsT("manageCards.form.surname.placeholder")}
                name="surname"
                value={user.surname}
                onChange={(event) => handleTextChange(event, "user")}
              />
            </div>
          </div>
          <div className="form-group mb-2">
            <label className="label-font text-color-dark d-block fw-700 label-font">
              {propsT("manageCards.form.address.label")}
            </label>
            <div className="form-group icon-tab bg-payment-white rounded-3">
              <input
                type="text"
                className="modal-input rounded-pill px-4 py-2"
                value={shippingAddress?.address}
                placeholder={propsT("manageCards.form.address.placeholder")}
                name="address"
                onChange={(event) => handleTextChange(event, "address")}
              />
            </div>
          </div>
          <div className="col-sm-6 col-12 ">
            <div className="form-group mb-2">
              <label className="label-font text-color-dark d-block fw-700 label-font">
                {propsT("manageCards.form.postcode.label")}
              </label>
              <div className="form-group icon-tab bg-payment-white rounded-3">
                <input
                  type="text"
                  className="modal-input rounded-pill px-4 py-2"
                  value={shippingAddress?.postCode}
                  placeholder={propsT("manageCards.form.postcode.placeholder")}
                  name="postCode"
                  onChange={(event) => handleTextChange(event, "address")}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-12 ">
            <div className="form-group mb-2">
              <label className="label-font text-color-dark d-block fw-700 label-font">
                {propsT("manageCards.form.city.label")}
              </label>
              <div className="form-group icon-tab bg-payment-white rounded-3">
                <input
                  type="text"
                  className="modal-input rounded-pill px-4 py-2"
                  value={shippingAddress?.city}
                  placeholder={propsT("manageCards.form.city.placeholder")}
                  name="city"
                  onChange={(event) => handleTextChange(event, "address")}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="form-group mb-2">
              <label className="label-font text-color-dark d-block fw-700 label-font">
                {propsT("manageCards.form.state.label")}
              </label>
              <div className="form-group icon-tab bg-payment-white rounded-3">
                <input
                  type="text"
                  className="modal-input rounded-pill px-4 py-2"
                  value={shippingAddress?.state}
                  placeholder={propsT("manageCards.form.state.placeholder")}
                  name="state"
                  onChange={(event) => handleTextChange(event, "address")}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="form-group mb-2">
              <label className="label-font text-color-dark d-block fw-700 label-font">
                {propsT("manageCards.form.country.label")}
              </label>
              <div
                className="stripe-input rounded-pill"
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}
              >
                <select
                  className="border-0 bg-payment-white font-dark font-xsss ms-1 w-95 text-color-dark"
                  value={country}
                  onChange={handleSelectedCountry}
                >
                  <option id="option-null" defaultChecked value={null}>
                    {propsT("manageCards.form.country.placeholder")}
                  </option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country.sortname}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 my-2">
            <div className="form-group mb-1">
              <label className="text-color-dark font-xssss fw-bold">
                {propsT("manageCards.form.cardNumber")}
              </label>
              <span className="flex-paginator">
                <div className="" style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="modal-input rounded-pill px-4 py-2"
                    value={cardDetails?.cardNumberDisplay}
                    placeholder={"1234 1234 1234 1234"}
                    name="state"
                    onKeyDown={(event) => handleCardChange(event)}
                  />
                </div>
                <div style={{ top: "-7px" }} className="icons-add-card">
                  <BiSolidCreditCardFront></BiSolidCreditCardFront>
                </div>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-12 my-2">
            <div className="form-group mb-1">
              <label className="text-color-dark font-xssss fw-bold">
                {propsT("manageCards.form.expire.label")}
              </label>
              <span className="flex-paginator">
                <div className="">
                  <input
                    type="text"
                    className="modal-input rounded-pill px-4 py-2"
                    value={cardDetails?.cardExpiry}
                    placeholder={"MM/YY"}
                    name="state"
                    maxLength={5}
                    onChange={(event) => handleExpDateChange(event)}
                  />
                </div>
                <div className="icons-add-card" style={{ top: "-7px" }}>
                  <BiSolidCalendarEvent></BiSolidCalendarEvent>
                </div>
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-12 my-2">
            <div className="form-group mb-1">
              <label className="text-color-dark font-xssss fw-bold">
                {propsT("manageCards.form.cvc.label")}
              </label>
              <span className="flex-paginator">
                <div className="">
                  <input
                    type="text"
                    className="modal-input rounded-pill px-4 py-2"
                    value={cardDetails?.cardCvc}
                    placeholder={"000"}
                    name="state"
                    maxLength={3}
                    onChange={(event) => handleCvvChange(event)}
                  />
                </div>
                <div className="icons-add-card" style={{ top: "-7px" }}>
                  <MdInfo></MdInfo>
                </div>
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex p-2 justify-content-end">
        <div
          className="pointer fw-bold me-4 font-xsss text-silver"
          onClick={() => handleModal(false)}
        >
          {propsT("common.cancel")}
        </div>

        <div
          className="fw-bold me-2 rounded-pill bg-green font-xssss text-white px-3 py-2 pointer"
          onClick={submitCcbill}
          disabled
        >
          {propsT("manageCards.addCard")}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewCardModalCcbill;
