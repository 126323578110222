import RequestService from "./RequestService";

const isDev = process.env.REACT_APP_ENV === "development";

const CcbillService = {
  ccbillToken: function (params) {
    return RequestService.get(`/ccbill/oauth`, params);
  },
  ccbillAddCard: function (params) {
    // params sono qui per le magie
    let newParams = params;
    // newParams.country
    return RequestService.post(`/ccbill/add-card`, newParams);
  },
  getAddedCardList: function () {
    return RequestService.get(`/ccbill/card-list`);
  },

  // createPaymentToken(params, token) {
  //   return RequestService.postCcbill(
  //     `/payment-tokens/threeds-required${
  //       (isDev ? "?body=" + JSON.stringify(params) : "") +
  //       (isDev ? "&token=" + token : "")
  //     }`,
  //     params,
  //     {
  //       Authorization: "Bearer " + token,
  //     }
  //   );
  // },

  paymentRequire3DS(paymentToken, token) {
    return RequestService.getCcbill(
      `/payment-tokens/${paymentToken}/threeds-required?token=${token}`,
      {
        Authorization: "Bearer " + token,
      }
    );
  },

  createPaymentToken: function (params, token) {
    return RequestService.postCcbill(
      `/payment-tokens/merchant-only-verify${
        (isDev ? "?body=" + JSON.stringify(params) : "") +
        (isDev ? "&token=" + token : "")
      }`,
      params,
      {
        Authorization: "Bearer " + token,
      }
    );
  },
};

export default CcbillService;

// process.env.REACT_APP_ENV
